import { Component, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { ProductService } from 'src/app/services/product.service';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { of as observableOf } from 'rxjs';
import { RoleCheckerService } from 'src/app/services/role-checker.service';
import { CompanyService } from 'src/app/services/company.service';
import { CommonService } from 'src/app/services/common.service';
import { AppSettings } from 'src/app/configs/app-settings';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { JwtService } from 'src/app/services/jwt.service';
import { AssetInfoFilterData } from 'src/app/models/asset-info-filters.model';
import { DateHelper } from 'src/app/shared/helpers/date-helper';

export interface PeriodicElement {
  date: string;
  status: string;
  company: string;
  assetIdName: string;
  assetIdValue: string;
  state: string;
  version: string;
  product: string;
}

export interface FiltersTypes {
  companies: Array<any>,
  assetIdNames: Array<any>
  products: Array<any>,
  states: Array<any>,
  statusCodes: Array<any>,
  versions: Array<string>
}

@Component({
  selector: 'app-asset-info-usage',
  templateUrl: './asset-info-usage.component.html',
  styleUrls: ['./asset-info-usage.component.scss']
})
export class AssetInfoUsageComponent {
  pageTitle: string = "";
  public displayedColumns: string[] = ['date', 'status', 'assetIdName', 'assetIdValue', 'state', 'version', 'product'];
  public filtersData: FiltersTypes = {
    companies: [],
    assetIdNames: [],
    products: [],
    states: [],
    statusCodes: [],
    versions: []
  };
  public filtersLoading: boolean = false;
  public searchTextboxControl = new UntypedFormControl();
  public selectFormControl = new UntypedFormControl();
  public selectedCompany: any;
  public filteredCompanyList: Array<any> = [];
  public minDate = new Date();
  public maxDate = new Date();
  public dateFormGroup: UntypedFormGroup;
  public fromDate: any = '';
  public toDate: any = '';
  public formattedFromDate: any = '';
  public formattedToDate: any = '';

  public loggedInCompanyId: number;
  public selectedCompanyId: number;
  public selectedAssetIdName: string = "";
  public selectedAssetIdValue: string = "";
  public selectedStatusCode: string = "";
  public selectedVersion: string = "";
  public selectedState: string = "";
  public selectedProducts: string = "";
  public companySelectionError: boolean = false;

  public exportDataOptions: Array<{
    key: string,
    value: number
  }> = [];
  public exportRecordsLimit: number = 2500;
  public selectedExportPage: number = 0;
  public isExportingRecords: boolean = false;

  public pageSizes = [50, 100, 200];
  public perPageLimit = 50;
  public isDataLoading: boolean = false;
  public usageData = [];
  public totalRecords: number = 0;
  public noRecoredFound: boolean = false;
  @ViewChild('search', { static: false }) searchTextBox: ElementRef;
  @ViewChild('paginator', { static: false }) paginator: MatPaginator;
  public loadingCompanyList: boolean = false;
  public isSuperAdmin: boolean = false;
  constructor(
    public productService: ProductService,
    public roleCheckService: RoleCheckerService,
    public companyService: CompanyService,
    public commonService: CommonService,
    public appSettings: AppSettings,
    private jwtService: JwtService,
    public assetInfoFiltersData: AssetInfoFilterData,
    private dateHelper: DateHelper
  ) {
    this.initialiseFilters();
  }

  initialiseFilters() {
    this.filtersData.assetIdNames = this.assetInfoFiltersData.assetIdName;
    this.filtersData.products = this.assetInfoFiltersData.v1Products;
    this.filtersData.states = this.assetInfoFiltersData.states;
    this.filtersData.statusCodes = this.assetInfoFiltersData.statusCodes;
    this.filtersData.versions = this.assetInfoFiltersData.versions;
    this.dateFormGroup = new UntypedFormGroup({
      fromDate: new UntypedFormControl(),
      toDate: new UntypedFormControl()
    });
  }

  ngOnInit() {
    const date = new Date();
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    this.minDate = new Date(firstDayOfMonth);
    this.setPageTitle();
    this.isSuperAdmin = this.roleCheckService.isSuperAdmin();
    this.searchTextboxControl.valueChanges.subscribe(res => {
      this.filteredCompanyList = this._filter(res);
    });
    if (this.isSuperAdmin) {
      this.getCompaniesList();
    } else {
      this.selectedCompanyId = this.jwtService.getCompanyId();
      setTimeout(() => {
        this.applyFilter();
      }, 100);
    }
    this.dateFormGroup.patchValue({
      fromDate: new Date(this.dateHelper.getMonthStartDate()),
      toDate: new Date()
    });
  }

  setPageTitle() {
    this.pageTitle = "Datium Asset Info Usage " + (this.totalRecords > 0 ? `(${this.totalRecords})` : '');
  }

  getCompaniesList() {
    this.loadingCompanyList = true;
    const pagingParams = {
      page: 1,
      limit: 1000, //it can change
      sort: 'asc',
      product: 'AssetInfo'
    }
    this.companyService.getCompanyListing(pagingParams).subscribe(response => {
      try {
        if (response.data.total) {
          this.filtersData.companies = response.data.records;
          this.filteredCompanyList = response.data.records;
        }
        this.loadingCompanyList = false;
      }
      catch (e) {
        this.commonService.commonSnakeBar();
      }
      this.loadingCompanyList = false;
    }, (err) => {
      const error = err;
      this.loadingCompanyList = false;
      if (error.message) {
        this.commonService.showSnakeBar(error.message);
      } else {
        this.commonService.commonSnakeBar();
      }
    })
  }

  itemSelected(event: any, type: string) {
    const selectedValue = event.value;
    this.selectedProducts = "";
    if (type === 'assetIdName') {
      if (this.assetInfoFiltersData.nonNavdisProducts.includes(selectedValue)) {
        this.filtersData.products = this.assetInfoFiltersData.nvicRedbookProducts;
      } else if (this.selectedVersion === 'v1.0') {
        this.filtersData.products = this.assetInfoFiltersData.v1Products;
      } else if (this.selectedVersion === 'v2.0') {
        this.filtersData.products = this.assetInfoFiltersData.v2Products;
      }
    } else if (type === 'version' && (selectedValue === 'v1.0' || selectedValue === '')) {
      if (!this.assetInfoFiltersData.nonNavdisProducts.includes(this.selectedAssetIdName)) {
        this.filtersData.products = this.assetInfoFiltersData.v1Products;
      }
    } else if (type === 'version' && selectedValue === 'v2.0') {
      if (!this.assetInfoFiltersData.nonNavdisProducts.includes(this.selectedAssetIdName)) {
        this.filtersData.products = this.assetInfoFiltersData.v2Products;
      }
    } else {
      this.filtersData.products = this.assetInfoFiltersData.v1Products;
    }
  }



  fetchUsageDetailData(type: string) {
    if (type === 'reset') {
      this.paginator.pageIndex = 0;
      this.paginator.pageSize = this.pageSizes[0];
    }
    this.paginator.page
      .pipe(
        startWith({}),
        switchMap(() => {

          return this.getUsageData(
            this.paginator.pageIndex + 1,
            this.paginator.pageSize
          ).pipe(catchError(() => observableOf(null)));
        }),
        map((response) => {
          this.noRecoredFound = false;
          if (response == null) return [];
          return response.data;
        })
      )
      .subscribe((data) => {
        try {
          this.usageData = data;
          if (this.usageData.length) {
            this.totalRecords = parseInt(this.usageData[0].Count);
            this.prepareDownloadDropdown();
            this.setPageTitle();
          } else {
            this.totalRecords = 0;
            this.noRecoredFound = true;
          }
          this.isDataLoading = false;
        }
        catch (e) {
          this.commonService.commonSnakeBar();
        };
      }, (err) => {
        const error = err;
        this.isDataLoading = false;
        if (error.message) {
          this.commonService.showSnakeBar(error.message);
        } else {
          this.commonService.commonSnakeBar();
        }
      });
  }

  prepareDownloadDropdown() {
    this.selectedExportPage = 0
    this.exportDataOptions = [];
    if (this.totalRecords > this.exportRecordsLimit) {
      const division = Math.ceil((this.totalRecords / this.exportRecordsLimit));
      for (let i = 1; i <= division; i++) {
        this.exportDataOptions.push({
          value: i,
          key: i < division ? `${((i - 1) * this.exportRecordsLimit) + 1} to ${i * this.exportRecordsLimit}` : `${((i - 1) * this.exportRecordsLimit) + 1} to ${this.totalRecords}`
        })
      }
    }
  }

  applyFilter() {
    if ((this.isSuperAdmin && this.selectedCompanyId) || !this.isSuperAdmin) {
      this.companySelectionError = false;
      this.fetchUsageDetailData('reset');
    } else {
      this.companySelectionError = true;
    }
  }

  getUsageData(pageNumber: number, pageSize: number) {
    this.isDataLoading = true;
    const params = {
      companyId: this.selectedCompanyId,
      page: pageNumber,
      pageSize: pageSize,
      assetIdName: this.selectedAssetIdName,
      assetIdValue: this.selectedAssetIdValue,
      sateRego: this.selectedState,
      startDate: this.formattedFromDate !== '' ? this.formattedFromDate : this.dateHelper.getMonthStartDate(),
      endDate: this.formattedToDate !== '' ? this.formattedToDate : this.dateHelper.getMonthCurrentDate(),
      statusCode: this.selectedStatusCode,
      apiVersion: this.selectedVersion,
      products: this.selectedProducts
    };
    return this.productService.assetInfoUsageDetails(params);
  }

  downloadReport() {
    if (this.exportDataOptions.length > 0 && this.selectedExportPage === 0) {
      return false;
    }
    let page = 1;
    if (this.totalRecords > this.exportRecordsLimit) {
      page = this.selectedExportPage;
    }
    const params = {
      companyId: this.selectedCompanyId,
      page: page,
      pageSize: this.exportRecordsLimit,
      assetIdName: this.selectedAssetIdName,
      assetIdValue: this.selectedAssetIdValue,
      sateRego: this.selectedState,
      startDate: this.formattedFromDate,
      endDate: this.formattedToDate,
      statusCode: this.selectedStatusCode,
      apiVersion: this.selectedVersion,
      products: this.selectedProducts,
      reqType: "download"
    };
    this.isExportingRecords = true;
    this.productService.assetInfoUsageDetailsFile(params).subscribe({
      next: (response) => {
        const filename = 'Asset-Info-Usage-Report.xlsx';
        var blob = new Blob([response], { type: 'application/xlsx' });
        if (navigator.msSaveBlob) {
          // IE 10+
          navigator.msSaveBlob(blob, filename);
        }
        else {
          var link = document.createElement("a");
          // Browsers that support HTML5 download attribute
          if (link.download !== undefined) {
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
        this.isExportingRecords = false;
      },
      error: (error) => {
        this.isExportingRecords = false;
      },
      complete: () => {
        this.isExportingRecords = false;
      }
    });
  }

  onCompanyChange(event) {
    this.selectedCompanyId = event.value
  }

  openedChange(e) {
    this.companySelectionError = false;
    // // Set search textbox value as empty while opening selectbox
    //this.searchTextboxControl.patchValue('');
    // // Focus to search textbox while clicking on selectbox
    if (e == true) {
      this.searchTextBox.nativeElement.focus();
    }
  }

  dateChange(type: string, event: MatDatepickerInputEvent<Date>) {
    if (event.value && event.value != null) {
      if (type == 'fromDate') {
        this.fromDate = event.value.toLocaleDateString();
        this.formattedFromDate = event.value.getFullYear().toString() + "-" + (event.value.getMonth() + 1).toString() + '-' + event.value.getDate().toString();
      } else if (type == 'toDate') {
        this.toDate = event.value.toLocaleDateString()
        this.formattedToDate = event.value.getFullYear().toString() + "-" + (event.value.getMonth() + 1).toString() + '-' + event.value.getDate().toString();
      }
    }
  }

  selectionChange(event) {
    this.selectedCompany = event;
    this.selectedCompanyId = event.CompanyId;
  }

  /**
   * Clearing search textbox value
   */
  clearSearch(event) {
    event.stopPropagation();
    this.selectedCompany = null;
    this.searchTextboxControl.patchValue('');
  }

  clearFilters() {
    if (this.isSuperAdmin) {
      this.selectFormControl.patchValue('');
      this.selectedCompanyId = null;
    }
    this.selectedAssetIdName = "";
    this.selectedAssetIdValue = "";
    this.selectedState = "";
    this.selectedVersion = "";
    this.selectedStatusCode = "";
    this.selectedProducts = "";
    this.formattedFromDate = "";
    this.formattedToDate = "";
    this.dateFormGroup.controls['fromDate'].setValue(undefined);
    this.dateFormGroup.controls['toDate'].setValue(undefined);
    this.usageData = [];
    this.totalRecords = 0;
    this.exportDataOptions = [];
    this.setPageTitle();
  }


  private _filter(name: string): any {
    const filterValue = name.toLowerCase();
    // Set selected values to retain the selected checkbox state
    this.selectFormControl.patchValue(this.selectedCompany);
    let filteredList = this.filtersData.companies.filter((option: any) => {
      return option.CompanyName.toLowerCase().indexOf(filterValue) > -1
    });
    return filteredList;
  }
}
