import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { ProductService } from 'src/app/services/product.service';
import { RoleCheckerService } from 'src/app/services/role-checker.service';
import { UserService } from 'src/app/services/user.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { of as observableOf } from 'rxjs';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { CompanyService } from 'src/app/services/company.service';
import { AppSettings } from 'src/app/configs/app-settings';

export interface DataModel {
  "ValuationDate": string,
  "Make": string,
  "Family": string,
  "VehicleDescription": string,
  "RegistrationState": string,
  "RequestComplianceDate": string,
  "Odometer": number,
  "SaleRegion": string,
  "SaleCategory": string,
  "ValuationLow": number,
  "Valuation": number,
  "ValuationHigh": number,
  "Count": number
};

@Component({
  selector: 'app-salvageval-usage',
  templateUrl: './salvageval-usage.component.html',
  styleUrls: ['./salvageval-usage.component.scss']
})
export class SalvagevalUsageComponent {
  pageTitle: string = "";
  @ViewChild('fromInput', {
    read: MatInput, static: false
  }) fromInput: MatInput;

  @ViewChild('toInput', {
    read: MatInput, static: false
  }) toInput: MatInput;

  displayedColumns: string[] = [
    'Valuation_DateTime',
    'Make',
    'Model',
    'Vehicle_Details',
    'Registration_State',
    'Compliance_Date',
    'Odometer',
    'Sale_Region',
    'Sale_Type',
    'Valuation_Low',
    'Valuation',
    'Valuation_High',
  ];

  companyId = ''
  companiesListing: any = {}
  makesList = [];
  make: string = '';
  modelList = [];
  model: string = '';
  regoStateList: Array<string> = [];
  regoState: string = '';
  saleRegionList: Array<string> = [];
  saleRegion: string = '';
  saleTypeList: Array<string> = [];
  saleType: string = '';
  isProcessing: boolean = false;
  minOdometer: any = 2000;
  maxOdometer: any = 350000;

  startDate = new Date()
  endDate = new Date()
  fromDate: any = '';
  toDate: any = '';
  formattedFromDate: any = '';
  formattedToDate: any = '';
  fromLabel: string = 'From';
  toLabel: string = 'To';
  isLoading: boolean = false;
  dataSource: DataModel[] = [];;
  valuationList = [];
  minDate = new Date();
  maxDate = new Date();
  dateFormGroup: UntypedFormGroup;
  public isSuperAdmin: boolean = false;
  public pageSizes = [50, 100, 200];
  public perPageLimit = 50;
  public totalRecords: number = 0;
  public noRecoredFound: boolean = false;
  public companySelectionError: boolean = false;
  public filteredOptions: any = [];
  public searchTextboxControl = new UntypedFormControl();
  public loadingCompanyList: boolean = false;
  @ViewChild('search', { static: false }) searchTextBox: ElementRef;
  @ViewChild('paginator', { static: false }) paginator: MatPaginator;
  constructor(
    private renderer: Renderer2,
    public productService: ProductService,
    public commonService: CommonService,
    public roleCheckService: RoleCheckerService,
    public userService: UserService,
    private router: Router,
    private dateHelper: DateHelper,
    public companyService: CompanyService,
    private appSettings: AppSettings
  ) {
    this.dateFormGroup = new UntypedFormGroup({
      fromDate: new UntypedFormControl(),
      toDate: new UntypedFormControl()
    });
  }

  ngOnInit() {
    this.regoStateList = this.appSettings.regoStateList;
    this.saleRegionList = this.appSettings.saleRegionList;
    this.saleTypeList = this.appSettings.saleTypeList;
    this.setPageTitle();
    this.isSuperAdmin = this.roleCheckService.isSuperAdmin();
    if (this.roleCheckService.isSuperAdmin()) {
      this.getCompaniesList();
      this.getUsageMakeModel();
    } else {
      this.getUsageMakeModel(null, true);
    }
    this.searchTextboxControl.valueChanges.subscribe(res => {
      this.filteredOptions = this._filter(res);
    });
    this.dateFormGroup.patchValue({
      fromDate: new Date(this.dateHelper.getMonthStartDate()),
      toDate: new Date()
    });
  }

  setPageTitle() {
    this.pageTitle = "SalvageVal Usage " + (this.totalRecords > 0 ? `(${this.totalRecords})` : '');
  }

  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();
    let filteredList = this.companiesListing.filter((option) => {
      return option.CompanyName.toLowerCase().indexOf(filterValue) === 0
    });
    return filteredList;
  }

  clearSearch(event) {
    event.stopPropagation();
    this.searchTextboxControl.patchValue('');
  }

  openedChange(e) {
    // Set search textbox value as empty while opening selectbox
    this.searchTextboxControl.patchValue('');
    // Focus to search textbox while clicking on selectbox
    if (e == true) {
      this.searchTextBox.nativeElement.focus();
    }
  }

  isNumber(evt) {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.,\b]+$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  formatnumber(value: any) {
    return parseInt(value.replace(/,/g, '').replaceAll('.', ''));
  }

  dateChange(type: string, event: MatDatepickerInputEvent<Date>) {
    if (event.value && event.value != null) {
      if (type == 'fromDate') {
        this.fromDate = event.value.toLocaleDateString()
        this.formattedFromDate = event.value.getFullYear().toString() + "-" + (event.value.getMonth() + 1).toString() + '-' + event.value.getDate().toString();
        this.fromLabel = (event.value.getMonth() + 1).toString() + '/' + event.value.getDate().toString() + '/' + event.value.getFullYear().toString()
      } else if (type == 'toDate') {
        this.toDate = event.value.toLocaleDateString()
        this.formattedToDate = event.value.getFullYear().toString() + "-" + (event.value.getMonth() + 1).toString() + '-' + event.value.getDate().toString();
        this.toLabel = event.value.getDate().toString() + '/' + (event.value.getMonth() + 1).toString() + '/' + event.value.getFullYear().toString()
      }
    }
  }

  getCompaniesList() {
    this.isProcessing = true;
    const pagingParams = {
      page: 1,
      limit: 1000, //it can change
      sort: 'asc',
      product: 'SalvageVal'
    }
    this.companyService.getCompanyListing(pagingParams).subscribe(response => {
      try {
        if (response.data.total) {
          this.companiesListing = response.data.records;
          this.filteredOptions = this.companiesListing;
        }
      }
      catch (e) {
        this.commonService.commonSnakeBar();
      }
      this.isProcessing = false;
    }, (err) => {
      const error = err;
      this.isProcessing = false;
      if (error.message) {
        this.commonService.showSnakeBar(error.message);
      } else {
        this.commonService.commonSnakeBar();
      }
    })
  }

  onCompanyChange(event) {
    this.companyId = event.value
  }

  onMakeChange(event) {
    this.make = event.value
    if (event.value == '') {
      this.model = '';
      this.modelList = [];
    }
    else {
      this.getUsageMakeModel(this.make)
    }
  }

  onModelChange(event) {
    this.model = event.value
  }

  onRegoStateChange(event) {
    this.regoState = event.value
  }

  onSaleRegionChange(event) {
    this.saleRegion = event.value
  }

  onSaleTypeChange(event) {
    this.saleType = event.value
  }

  applyFilter() {
    var error = this.validateFilter();
    if (error.length > 0) {
      this.commonService.showSnakeBar(error);
      return;
    }
    if ((this.isSuperAdmin && this.companyId) || !this.isSuperAdmin) {
      this.companySelectionError = false;
      this.fetchUsageDetailData();
    } else {
      this.companySelectionError = true;
    }
  }

  clearFilter() {
    this.companyId = '';
    this.make = '';
    this.model = '';
    this.regoState = '';
    this.saleRegion = '';
    this.saleType = '';
    this.modelList = [];
    this.minOdometer = 2000;
    this.maxOdometer = 350000;
    this.formattedFromDate = '';
    this.formattedToDate = '';
    this.dateFormGroup.controls['fromDate'].setValue(undefined);
    this.dateFormGroup.controls['toDate'].setValue(undefined);
    this.noRecoredFound = false;
    this.dataSource = [];
    this.totalRecords = 0;
    this.paginator.pageSize = 50;
    this.setPageTitle();
  }
  getUsageMakeModel(make = null, search = false) {
    const reqModel = make == null ? {
      'RequestedVehicleIDName': 'MakeCode'
    } : {
      'RequestedVehicleIDName': 'FamilyCode',
      'MakeCode': make
    }
    this.isProcessing = true;
    this.productService.salvageValUsageMakeModel(reqModel).subscribe(res => {
      try {
        this.isProcessing = false;
        const response = res;
        if (make == null) {
          this.makesList = response.data;
        }
        else {
          this.modelList = response.data;
        }
        if (search) {
          this.applyFilter();
        }
      }
      catch (e) {
        this.isProcessing = false;
      }
    }, (err) => {
      this.isProcessing = false;
      let response = err;
      if (response.message) {
        this.commonService.showSnakeBar(response.message);
      } else {
        this.commonService.commonSnakeBar();
      }
    })
  }

  fetchUsageDetailData() {
    this.paginator.page
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.getUsageDetails(
            this.paginator.pageIndex + 1,
            this.paginator.pageSize
          ).pipe(catchError(() => observableOf(null)));
        }),
        map((response) => {
          this.noRecoredFound = false;
          if (response == null) return [];
          return response.data;
        })
      )
      .subscribe((data) => {
        try {
          this.isProcessing = false;
          this.dataSource = data;
          if (this.dataSource.length) {
            this.totalRecords = this.dataSource[0].Count;
            this.setPageTitle();
          } else {
            this.totalRecords = 0;
            this.noRecoredFound = true;
          }
          this.isLoading = false;
        }
        catch (e) {
          this.commonService.commonSnakeBar();
        };
      }, (err) => {
        const error = err;
        this.isLoading = false;
        if (error.message) {
          this.commonService.showSnakeBar(error.message);
        } else {
          this.commonService.commonSnakeBar();
        }
      });
  }

  getUsageDetails(pageNumber: number, pageSize: number) {
    let reqModel;
    reqModel = {
      companyId: this.companyId,
      page: pageNumber,
      pageSize: pageSize,
      make: this.make,
      family: this.model,
      registrationState: this.regoState,
      minOdometer: this.minOdometer ? this.formatnumber(this.minOdometer.toString()) : '',
      maxOdometer: this.maxOdometer ? this.formatnumber(this.maxOdometer.toString()) : '',
      saleRegion: this.saleRegion,
      saleCategory: this.saleType,
      startDate: this.formattedFromDate !== '' ? this.formattedFromDate : this.dateHelper.getMonthStartDate(),
      endDate: this.formattedToDate !== '' ? this.formattedToDate : this.dateHelper.getMonthCurrentDate()
    }
    if (!this.roleCheckService.isSuperAdmin()) {
      delete reqModel.companyId;
    }
    this.isProcessing = true;
    this.isLoading = true;
    return this.productService.salvageValUsageDetails(reqModel);
  }

  validateFilter(): string {
    var error = '';
    var isvalid = true;

    if (this.roleCheckService.isSuperAdmin()) {
      if (!this.companyId || this.companyId == '') {
        error = 'Please Choose Company';
        isvalid = false;
      }
    }

    if (isvalid) {
      if (this.commonService.validateIsEmpty(this.fromDate) && this.commonService.validateIsEmpty(this.toDate)) {
      } else {
        if (this.commonService.validateIsEmpty(this.fromDate) || this.commonService.validateIsEmpty(this.toDate)) {
          error = 'Invalid Date Range format';
          isvalid = false
        }
        else {
          if (!this.validateDatefomat(this.fromDate) || !this.validateDatefomat(this.toDate)) {
            error = 'Invalid Date Range format';
            isvalid = false
          }
        }
      }
    }

    if (isvalid) {
      if (this.commonService.validateIsEmpty(this.minOdometer) && this.commonService.validateIsEmpty(this.maxOdometer)) {

      }
      else {
        if (!isNaN(Number(this.minOdometer)) && !isNaN(Number(this.maxOdometer))) {
          if (this.minOdometer < 1) {
            error = 'MinOdometer should greater than 0';
            isvalid = false
          } else if (this.maxOdometer < 1) {
            error = 'MaxOdometer should greater than 0';
            isvalid = false
          }
          else if (Number(this.minOdometer) > Number(this.maxOdometer)) {
            error = 'MinOdometer should be less than or equal to MaxOdometer';
            isvalid = false
          }
        }
        else if (this.commonService.validateIsEmpty(this.minOdometer) || this.commonService.validateIsEmpty(this.maxOdometer) || isNaN(Number(this.minOdometer)) || isNaN(Number(this.maxOdometer))) {
          error = 'Invalid Odometer Range';
          isvalid = false
        }
      }
    }

    return error;
  }
  validateDatefomat(date): boolean {
    try {
      let patt = new RegExp('^[0-9]{1,2}(/|-)[0-9]{1,2}(/|-)[0-9]{4}$');
      if (!patt.test(date)) {
        return false;
      }
      else {
        return true;
      }
    } catch (error) {
      return false;
    }
  }

}
